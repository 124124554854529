<template>
    <div :class='{"kt-spinner fix-spinner-index kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input" : isPhoneLoading }'>
        <div class="kt-input-icon kt-input-icon--left country-input" :class="isCountryComponent ? 'input-group': ''">
            <template v-if="isCountryComponent">
                <div class="input-group-prepend">
                    <vue-country-code
                        @onSelect="onSelect"
                        :preferredCountries="['id']" ></vue-country-code> 
                </div>
            </template>
            <template v-else>
                <span class="kt-input-icon__icon position-plus-country">
                    <span style="padding-bottom: 1px;">
                        +
                    </span>
                </span>
            </template>
            <input
                @input="changedData"
                type="text"
                class="form-control"
                v-model="phoneNumber"
                placeholder="Nomor Telepon"
                v-validate="rules"
                data-vv-validate-on="blur"
                data-vv-as="Nomor Telepon"
                :name="name"
                :class="{'is-invalid': errors.has(name), 'pl-1' : isCountryComponent, 'pl-25' :  !isCountryComponent}"
                :data-vv-scope="(scope != null) ? scope : null"
                :disabled="isDisabled"
            />
        </div>
        <small
            v-show="errors.first(name)"
            class="text text-danger"
            >{{ errors.first(name) }}</small>
    </div>
</template>

<script>
import { Validator } from 'vee-validate';
import VeeValidate from 'vee-validate';
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const PatientRepository = RepositoryFactory.get("patient");
import VueCountryCode from "vue-country-code";
VeeValidate.setMode('passive');
import SweetAlertTemplate from './SweetAlert.vue';
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
    components:{
        VueCountryCode
    },
    inject: ["$validator"],
    props: {
        data: { type: String },
        isValidate: { type: Boolean },
        isDisabled: { type: Boolean, default: false},
        scope: {type: String, default: null},
        oldPhoneNum: {type: String},
        editMode: {type: Boolean, default: false},
        phonePatient: {type: Boolean, default: false},
        isRepeater: { type: Boolean, default: false},
        index: {type: Number},
        isCountryComponent: {type: Boolean, default: false}
    },
    data() {
        return {
            name: "PhoneNumber",
            phoneNumber: null,
            oldNumber: null,
            isPhoneLoading: false,
            rules: { required: false, numeric:true, regex: /^[0-9]{8,16}$/, uniquePhone: false },
            abortController: null,
            numberCountry: '',
            isShowPopUp: false
        }
    },
    watch: {
        data: function(val){
            this.phoneNumber = (val != null) ? this.editPhone(val) : null;
        },
        oldPhoneNum: function(val){
            this.oldNumber = (val != null) ? this.editPhone(val) : null;
        },
    },
    methods: {
        onSelect({ dialCode}) {
            const element = document.getElementsByClassName('vue-country-select')[0]
            const current = element.getElementsByClassName('current')[0]
            if(current.getElementsByClassName('name-country').length > 0) {
                const span = document.getElementsByClassName('name-country')[0]
                span.innerHTML = `+${dialCode}`
            } else {
                const node = document.createElement('span')
                node.classList.add('name-country')
                node.appendChild(document.createTextNode(`+${dialCode}`))
                current.appendChild(node)
            }
            this.numberCountry = dialCode
            const numberPhone = `${this.numberCountry}${this.phoneNumber}`
            if(this.phoneNumber)
            this.$emit("update:changedValue", numberPhone);
            this.isShowPopUp = true
        },
        editPhone: function(data){
            if(this.isCountryComponent) return data.replace(this.numberCountry, '')
            else return data
        },
        changedData: function() {
            const numberTelp = this.isCountryComponent ? `${this.numberCountry}${this.phoneNumber}` : `${this.phoneNumber}`
            this.$emit("update:changedValue", `${numberTelp}`);
        },
        checkPhone: function(value, resolve){
           
            if(this.isValidate) {
                if (this.abortController) {
                    this.abortController.abort()
                }
                const numberTelp = this.isCountryComponent ? `${this.numberCountry}${value}` : `${value}`
                const params = `mobilePhone1:${numberTelp}`;
                const repo = PatientRepository.search({query:params})
                this.abortController = repo.next().value
                const reposi = repo.next().value
                repo.next()
                reposi.then((response) => {
                    this.isPhoneLoading = false;
                    if (response.data.length > 0) {
                        return resolve({
                            valid: false,
                            data: {
                            message: `Nomor telepon +${numberTelp} sudah terdaftar`
                            }
                        });
                    } else {
                        return resolve({
                            valid: true
                        });
                    }
                }).catch(err => {
                    this.isPhoneLoading = false;
                    const msg = (err.message) ? err.message : err;
                    this.showToastError('check telepon : ' + msg);
                    return resolve({
                        valid: false,
                        data : {
                            message: `Gagal Validasi Check Nomor Telepon`
                        }
                    })
                })
            } else {
                if(this.numberCountry != '62' && this.isShowPopUp) {
                    this.isShowPopUp = false
                new SweetAlert().showInfo({
                        swalTitle: "Info",
                        swalText: 'Notifikasi (SMS) tidak akan terkirim ke nomor selain nomor Indonesia (selain +62)', // response.data.message,
                        swalType: "info",
                        onConfirmButton: function() {
                            // done
                        }
                    });
            }
            this.isPhoneLoading = false;
                return resolve({
                    valid: true
                });
            }
            
            
        }
    },
    created() {
        this.phoneNumber = (this.data != null) ? this.editPhone(this.data) : null;
        this.oldNumber = (this.oldPhoneNum != null) ? this.editPhone(this.oldPhoneNum) : null;

        this.rules.required = this.isValidate
        this.rules.uniquePhone = this.phonePatient
        if(this.scope != null){
            this.name = this.scope+'.'+this.name;
        }
        if(this.isRepeater){
            this.name = this.name+'-'+this.index;
        }
    },
    mounted() {
        const isPhoneUnique = value =>
        new Promise(resolve => {
            this.errors.remove(this.name);
            this.isPhoneLoading = true
            if(this.editMode){
                if(value == this.oldNumber) {
                    this.isPhoneLoading = false;
                    return resolve({
                        valid: true
                    });
                } else {
                    this.checkPhone(value, resolve);
                }
            } else {
                this.checkPhone(value, resolve);
            }
        });
        Validator.extend("uniquePhone", {
            validate: isPhoneUnique,
            getMessage: (field, params, data) => data.message
        });
    },
}
</script>

<style>

</style>
